import { MongoClient, ObjectId } from "mongodb";
import { reconcileElements } from "../../../packages/excalidraw";
import type {
  ExcalidrawElement,
  FileId,
  OrderedExcalidrawElement,
} from "../../../packages/excalidraw/element/types";
import { getSceneVersion } from "../../../packages/excalidraw/element";
import type Portal from "../../collab/Portal";
import { restoreElements } from "../../../packages/excalidraw/data/restore";
import type {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
} from "../../../packages/excalidraw/types";
import { decompressData } from "../../../packages/excalidraw/data/encode";
import {
  encryptData,
  decryptData,
} from "../../../packages/excalidraw/data/encryption";
import { MIME_TYPES } from "../../../packages/excalidraw/constants";
import type { SyncableExcalidrawElement } from "..";
import { getSyncableElements } from "..";
import type { Socket } from "socket.io-client";
import type { RemoteExcalidrawElement } from "../../../packages/excalidraw/data/reconcile";

// ---

// Connection URL
const url = import.meta.env.VITE_APP_MONGODB_URL ?? "mongodb://localhost:27017";

// Database Name
const dbName = import.meta.env.VITE_APP_MONGODB_DB ?? "excalidraw";

const mongoClient = new MongoClient(url, {
  auth: {
    username: import.meta.env.VITE_APP_MONGODB_USER,
    password: import.meta.env.VITE_APP_MONGODB_PASSWORD,
  },
});

let isMongoInitialized = false;

let mongoPromise: Promise<MongoClient> | null = null;

const _loadMongo = async () => {
  if (!isMongoInitialized) {
    try {
      await mongoClient.connect();
    } catch (error: any) {
      // trying initialize again throws. Usually this is harmless, and happens
      // mainly in dev (HMR)
      if (error.code === "app/duplicate-app") {
        console.warn(error.name, error.code);
      } else {
        throw error;
      }
    }
    isMongoInitialized = true;
  }

  return mongoClient;
};

const _getMongo = async (): Promise<MongoClient> => {
  if (!mongoPromise) {
    mongoPromise = _loadMongo();
  }
  return mongoPromise;
};

// ----

class MongoSceneVersionCache {
  private static cache = new WeakMap<Socket, number>();
  static get = (socket: Socket) => {
    return MongoSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: Socket,
    elements: readonly SyncableExcalidrawElement[],
  ) => {
    MongoSceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };
}

// ---

interface MongoStoredScene {
  _id: ObjectId;
  roomId: string;
  sceneVersion: number;
  iv: Uint8Array;
  ciphertext: ArrayBuffer;
}

const createMongoDoc = async (
  roomId: string,
  elements: readonly SyncableExcalidrawElement[],
  roomKey: string,
) => {
  const sceneVersion = getSceneVersion(elements);
  const { ciphertext, iv } = await encryptElements(roomKey, elements);
  return {
    _id: new ObjectId(roomId),
    roomId,
    sceneVersion,
    ciphertext: new Uint8Array(ciphertext),
    iv,
  } as MongoStoredScene;
};

const decryptElements = async (
  data: any,
  roomKey: string,
): Promise<readonly ExcalidrawElement[]> => {
  const ciphertext = data.ciphertext;
  const iv = data.iv;

  const decrypted = await decryptData(iv, ciphertext, roomKey);
  const decodedData = new TextDecoder("utf-8").decode(
    new Uint8Array(decrypted),
  );
  return JSON.parse(decodedData);
};

const encryptElements = async (
  key: string,
  elements: readonly ExcalidrawElement[],
): Promise<{ ciphertext: ArrayBuffer; iv: Uint8Array }> => {
  const json = JSON.stringify(elements);
  const encoded = new TextEncoder().encode(json);
  const { encryptedBuffer, iv } = await encryptData(key, encoded);

  return { ciphertext: encryptedBuffer, iv };
};

const patchPrefix = (prefix: string): string => {
  return prefix.replaceAll("/", "#");
};

// ----

export const loadMongoStorage = async () => {
  const mongo = await _getMongo();
  // if (!firebaseStoragePromise) {
  //   firebaseStoragePromise = import(
  //     /* webpackChunkName: "storage" */ "firebase/storage"
  //   );
  // }
  // if (firebaseStoragePromise !== true) {
  //   await firebaseStoragePromise;
  //   firebaseStoragePromise = true;
  // }
  return mongo;
};

export const isSavedToMongo = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);

    return MongoSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const saveFilesToMongo = async ({
  prefix,
  files,
}: {
  prefix: string;
  files: { id: FileId; buffer: Uint8Array }[];
}) => {
  const mongo = await loadMongoStorage();

  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  const patchedPrefix = patchPrefix(prefix);

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        await mongo
          .db(dbName)
          .collection(patchedPrefix)
          .findOneAndUpdate(
            { _id: new ObjectId(id) },
            new Blob([buffer], {
              type: MIME_TYPES.binary,
            }),
            {
              upsert: true,
            },
          );
        savedFiles.set(id, true);
      } catch (error: any) {
        erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const saveToMongo = async (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
) => {
  const { roomId, roomKey, socket } = portal;
  if (
    // bail if no room exists as there's nothing we can do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    isSavedToMongo(portal, elements)
  ) {
    return null;
  }

  const storedScene = await (async function () {
    // check if exists, insert if not, update if it does

    const room = await (
      await _getMongo()
    )
      .db(dbName)
      .collection("scenes")
      .findOne({ _id: new ObjectId(roomId) });

    if (!room) {
      // insert
      const scene = createMongoDoc(roomId, elements, roomKey);
      await (await _getMongo())
        .db(dbName)
        .collection("scenes")
        .insertOne(scene);
      return scene;
    }
    // reconcile and update
    const storedScene = room as any as MongoStoredScene;
    const prevStoredElements = getSyncableElements(
      restoreElements(await decryptElements(storedScene, roomKey), null),
    );
    const reconciledElements = getSyncableElements(
      reconcileElements(
        elements,
        prevStoredElements as OrderedExcalidrawElement[] as RemoteExcalidrawElement[],
        appState,
      ),
    );
    const updatedScene = createMongoDoc(roomId, reconciledElements, roomKey);
    await (await _getMongo())
      .db(dbName)
      .collection("scenes")
      .insertOne(updatedScene);
    return updatedScene;
  })();

  const storedElements = getSyncableElements(
    restoreElements(await decryptElements(storedScene, roomKey), null),
  );

  MongoSceneVersionCache.set(socket, storedElements);

  return storedElements;
};

export const loadFromMongo = async (
  roomId: string,
  roomKey: string,
  socket: Socket | null,
): Promise<readonly SyncableExcalidrawElement[] | null> => {
  const room = await (
    await _getMongo()
  )
    .db(dbName)
    .collection("scenes")
    .findOne({ _id: new ObjectId(roomId) });

  if (!room) {
    return null;
  }
  const storedScene = room as any as MongoStoredScene;
  const elements = getSyncableElements(
    restoreElements(await decryptElements(storedScene, roomKey), null),
  );

  if (socket) {
    MongoSceneVersionCache.set(socket, elements);
  }

  return elements;
};

export const loadFilesFromMongo = async (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();
  const patchedPrefix = patchPrefix(prefix);

  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const file = await (
          await _getMongo()
        )
          .db(dbName)
          .collection(patchedPrefix)
          .findOne({ _id: new ObjectId(id) });

        if (file) {
          const arrayBuffer = await file.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};
