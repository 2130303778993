import type {
  ExcalidrawElement,
  FileId,
} from "../../packages/excalidraw/element/types";
import type Portal from "../collab/Portal";
import type { AppState, BinaryFileData } from "../../packages/excalidraw/types";
import type { SyncableExcalidrawElement } from ".";
import type { Socket } from "socket.io-client";

import {
  isSavedToFirebase,
  saveToFirebase,
  saveFilesToFirebase,
  loadFromFirebase,
  loadFilesFromFirebase,
  // loadFirebaseStorage,
} from "./storage-provider/firebase";

import {
  isSavedToMongo,
  saveToMongo,
  saveFilesToMongo,
  loadFromMongo,
  loadFilesFromMongo,
  // loadMongoStorage,
} from "./storage-provider/mongodb";

const ErrorNoStorageProvider = Error(
  "No StorageProvider defined, set VITE_APP_STORAGE_PROVIDER",
);

export interface StorageProvider {
  isSavedToStorage: (
    portal: Portal,
    elements: readonly ExcalidrawElement[],
  ) => boolean;

  saveToStorage: (
    portal: Portal,
    elements: readonly SyncableExcalidrawElement[],
    appState: AppState,
  ) => Promise<SyncableExcalidrawElement[] | null>;

  saveFilesToStorage: ({
    prefix,
    files,
  }: {
    prefix: string;
    files: {
      id: FileId;
      buffer: Uint8Array;
    }[];
  }) => Promise<{
    savedFiles: Map<FileId, true>;
    erroredFiles: Map<FileId, true>;
  }>;

  loadFromStorage: (
    roomId: string,
    roomKey: string,
    socket: Socket,
  ) => Promise<readonly SyncableExcalidrawElement[] | null>;

  loadFilesFromStorage: (
    prefix: string,
    decryptionKey: string,
    filesIds: readonly FileId[],
  ) => Promise<{
    loadedFiles: BinaryFileData[];
    erroredFiles: Map<FileId, true>;
  }>;

  // loadStorage: () => Promise<typeof firebase>;
}

const firebaseStorageProvider: StorageProvider = {
  isSavedToStorage: isSavedToFirebase,
  saveToStorage: saveToFirebase,
  saveFilesToStorage: saveFilesToFirebase,
  loadFromStorage: loadFromFirebase,
  loadFilesFromStorage: loadFilesFromFirebase,
};

const mongoStorageProvider: StorageProvider = {
  isSavedToStorage: isSavedToMongo,
  saveToStorage: saveToMongo,
  saveFilesToStorage: saveFilesToMongo,
  loadFromStorage: loadFromMongo,
  loadFilesFromStorage: loadFilesFromMongo,
};

const storageProviderID =
  import.meta.env.VITE_APP_STORAGE_PROVIDER ?? "firebase";

const storageProviderMap = new Map<string, StorageProvider>([
  ["firebase", firebaseStorageProvider],
  ["mongo", mongoStorageProvider],
]);

const storageProvider = storageProviderMap.get(storageProviderID);
if (!storageProvider) {
  throw ErrorNoStorageProvider;
}

// export const loadStorage = async () => {
//   return storageProvider.loadStorage
//   }
// };

export const isSavedToStorage = (
  portal: Portal,
  elements: readonly ExcalidrawElement[],
): boolean => {
  return storageProvider.isSavedToStorage(portal, elements);
};

export const saveToStorage = (
  portal: Portal,
  elements: readonly SyncableExcalidrawElement[],
  appState: AppState,
): Promise<SyncableExcalidrawElement[] | null> => {
  return storageProvider.saveToStorage(portal, elements, appState);
};

export const saveFilesToStorage = ({
  prefix,
  files,
}: {
  prefix: string;
  files: {
    id: FileId;
    buffer: Uint8Array;
  }[];
}): Promise<{
  savedFiles: Map<FileId, true>;
  erroredFiles: Map<FileId, true>;
}> => {
  return storageProvider.saveFilesToStorage({ prefix, files });
};

export const loadFromStorage = (
  roomId: string,
  roomKey: string,
  socket: Socket,
): Promise<readonly SyncableExcalidrawElement[] | null> => {
  return storageProvider.loadFromStorage(roomId, roomKey, socket);
};

export const loadFilesFromStorage = (
  prefix: string,
  decryptionKey: string,
  filesIds: readonly FileId[],
): Promise<{
  loadedFiles: BinaryFileData[];
  erroredFiles: Map<FileId, true>;
}> => {
  return storageProvider.loadFilesFromStorage(prefix, decryptionKey, filesIds);
};
